<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 15V9h4l5-5v16l-5-5z"></path>
  </svg>
</template>

<script>
export default {
  name: "MaterialSymbolsVolumeMute",
};
</script>
