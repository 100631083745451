<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M2.75 2.75h3.5v10.5h-3.5zm7 0h3.5v10.5h-3.5z"
    />
  </svg>
</template>

<script>
export default {
  name: "EosIconsLoading",
};
</script>
