import revive_payload_client_J4kJZla3BI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hhDwTeaMSa from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eWbZi14gzp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Y6za3iqi7J from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lhyVCHuqSK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lzVaTWNBK9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IOoQBjxQjg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_UMPSK6rZbw from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_rollup@4.22.0_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_7T7NqGFde4 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_8nxexhK8XL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Hdzm7ypIYr from "/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.22.0/node_modules/@nuxt/image/dist/runtime/plugin.js";
import hover_directive_At8g6KzWvE from "/plugins/hover-directive.js";
import cursor_state_IlpYgpzF9z from "/plugins/cursor-state.js";
import clarity_plugin_QwlOEPlX8y from "/plugins/clarity-plugin.js";
import gtag_client_h5Qz5d3asB from "/plugins/gtag.client.js";
import axeptio_kqwAdnYpJz from "/vercel/path0/plugins/axeptio.js";
import clarity_plugin_k7tv5rUjPe from "/vercel/path0/plugins/clarity-plugin.js";
import cursor_state_HIoVQqCLhS from "/vercel/path0/plugins/cursor-state.js";
import gsap_client_Z6XKCRtOCT from "/vercel/path0/plugins/gsap.client.js";
import gtag_client_R70iZRv6jK from "/vercel/path0/plugins/gtag.client.js";
import hover_directive_3IxHn9Lif2 from "/vercel/path0/plugins/hover-directive.js";
export default [
  revive_payload_client_J4kJZla3BI,
  unhead_hhDwTeaMSa,
  router_eWbZi14gzp,
  payload_client_Y6za3iqi7J,
  navigation_repaint_client_lhyVCHuqSK,
  check_outdated_build_client_lzVaTWNBK9,
  chunk_reload_client_IOoQBjxQjg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UMPSK6rZbw,
  switch_locale_path_ssr_7T7NqGFde4,
  i18n_8nxexhK8XL,
  plugin_Hdzm7ypIYr,
  hover_directive_At8g6KzWvE,
  cursor_state_IlpYgpzF9z,
  clarity_plugin_QwlOEPlX8y,
  gtag_client_h5Qz5d3asB,
  axeptio_kqwAdnYpJz,
  clarity_plugin_k7tv5rUjPe,
  cursor_state_HIoVQqCLhS,
  gsap_client_Z6XKCRtOCT,
  gtag_client_R70iZRv6jK,
  hover_directive_3IxHn9Lif2
]