import { default as _91_46_46_46app_93SV0Z8uyKfbMeta } from "/vercel/path0/pages/[...app].vue?macro=true";
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as countdownvtqFImsxdOMeta } from "/vercel/path0/pages/countdown.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as workssRZImgF9lMeta } from "/vercel/path0/pages/work.vue?macro=true";
export default [
  {
    name: "app",
    path: "/:app(.*)*",
    component: () => import("/vercel/path0/pages/[...app].vue")
  },
  {
    name: "about",
    path: "/about",
    meta: aboutcggDDldG7iMeta || {},
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "countdown",
    path: "/countdown",
    meta: countdownvtqFImsxdOMeta || {},
    component: () => import("/vercel/path0/pages/countdown.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/vercel/path0/pages/mentions-legales.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "work",
    path: "/work",
    meta: workssRZImgF9lMeta || {},
    component: () => import("/vercel/path0/pages/work.vue")
  }
]