
import * as vercelRuntime$X2jKX86T0z from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.22.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "png",
    "jpg",
    "gif",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$X2jKX86T0z, defaults: {} }
}
        