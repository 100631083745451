<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25.2 24.4"
    style="enable-background: new 0 0 25.2 24.4"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M19.1,21.8V0.5c0-0.1-0.1-0.2-0.2-0.1L2,10.8c-0.2,0.1-0.2,0.5,0,0.6l16.9,10.4C19,22,19.1,21.9,19.1,21.8z"
      />
      <line 
        fill="currentColor"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        stroke-miterlimit="10"
        class="st0" x1="2.7" x2="2.7" y2="19.9" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "CilMediaStepBackward",
};
</script>
