<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25.2 24.4"
    style="enable-background: new 0 0 25.2 24.4"
    xml:space="preserve"
  >
    <!-- <style type="text/css">
      .st0 {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
    </style> -->
    <path
      fill="currentColor"
      d="M2.8,2.4V23c0,0.3,0.3,0.5,0.5,0.3L20.5,13c0.2-0.1,0.2-0.5,0-0.6L3.3,2.1C3.1,2,2.8,2.2,2.8,2.4z"
    />
    <line
      fill="currentColor" 
      x1="19.8" y1="4" x2="19.8" y2="21.5" />
  </svg>
</template>

<script>
export default {
  name: "CilMediaStepForward",
};
</script>
